import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

// Style imports
import './PostControls.scss';

const PostControls = ({
    previous,
    next,
}) => {
    return (
        <nav className='post-controls' aria-label='Blog post controls'>
            {(previous || next) && (
                <ul className='post-controls__list'>
                    {previous && (
                        <li className='post-controls__list-item'>
                            <Link className='post-controls__link post-controls__link--previous button' to={`/blog${previous.fields.slug}`} rel='prev'>
                                <span className='post-controls__link-text'>Previous article</span>
                            </Link>
                        </li>
                    )}

                    {next && (
                        <li className={`post-controls__list-item${!previous ? ' post-controls__list-item--only' : ''}`}>
                            <Link className='post-controls__link button' to={`/blog${next.fields.slug}`} rel='next'>
                                <span className='post-controls__link-text'>Next article</span>
                            </Link>
                        </li>
                    )}
                </ul>
            )}
        </nav>
    );
};

PostControls.propTypes = {
    previous: PropTypes.object,
    next: PropTypes.object,
};

export default PostControls;