import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import commentBox from 'commentbox.io';

// Layout Imports
import Default from '../Default/Default';
import SEO from '../SEO/SEO';

// Component Imports
import Section from '../../components/shared/Section/Section'
import TagList from '../../components/shared/TagList/TagList';
import PageHeader from '../../components/shared/PageHeader/PageHeader';
import PostControls from '../../components/PostControls/PostControls';
import PostShare from '../../components/PostShare/PostShare';

// Style Imports
import './BlogPost.scss';
import './Typography.scss';

// Asset imports
import Avatar from '../../assets/images/content/youtube-avatar.jpg';

const BlogPost = (props) => {
  const post = props.data.mdx;
  const siteTitle = props.data.site.siteMetadata.title;
  const { previous, next } = props.pageContext;

  useEffect(() => {
    commentBox('5737942232334336-proj', {
      textColor: '#1A2440',
      subtextColor: '#1A2440',
    });
  }, []);

  return (
    <Default
      location={props.location}
      title={siteTitle}
    >
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
      />

      <PageHeader
        heading={post.frontmatter.title}
        description={post.frontmatter.description}
      />

      <Section accent>
        <div className='blog-post__header'>
          <div className='blog-post__info'>
            {post.frontmatter.author === 'ParkyGames' && (
              <div className='blog-post__avatar-wrapper'>
                <img className='blog-post__avatar' src={Avatar} alt='' aria-hidden='true' />
              </div>
            )}
            {post.frontmatter.author}
          </div>
          <p className='blog-post__info'>Posted {post.frontmatter.date}</p>
          <div className='blog-post__info blog-post__info--column'>
            {post.frontmatter.tags && post.frontmatter.tags.length && (
              <TagList
                tags={post.frontmatter.tags}
                prepend='Tags'
              />
            )}

            <PostShare />
          </div>
        </div>

        <div className='blog-post__body'>
          <div className='blog-post__sidebar'>
            <PostShare variant='vertical' />
          </div>
          
          <article className='blog-post__body-inner typography'>
            <MDXRenderer>{post.body}</MDXRenderer>
          </article>
        </div>
      
        <PostControls
          previous={previous}
          next={next}
        />

        <div className="commentbox" />
      </Section>
    </Default>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        author
        tags
      }
    }
  }
`;