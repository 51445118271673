import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Style imports
import './PostShare.scss';

const PostShare = ({
    variant,
    sticky,
}) => {
    const [state, setState] = useState({
        copied: false,
    });

    // Current page info
    const url = typeof window !== 'undefined' ? window.location.href : '';
    const pageName = typeof window !== 'undefined' ? window.document.title : '';

    const baseClass = 'post-share';
    const variantClass = variant ? `post-share--${variant}` : '';
    const stickyClass = sticky ? 'post-share--sticky' : '';
    const classes = [baseClass, variantClass, stickyClass].filter(Boolean).join(' ');

    const handleCopy = (event) => {
        event.preventDefault();

        navigator.clipboard.writeText(url);

        setState({...state, copied: true});

        setTimeout(() => {
            setState({...state, copied: false});
        }, 2000)
    };

    return (
        <section className={classes}>
            <h2 className='post-share__heading'>Share</h2>

            <ul className='post-share__list'>
                <li className='post-share__item'>
                    <a
                        className='post-share__link post-share__link--facebook'
                        href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
                        target='_blank'
                        rel="noopener noreferrer"
                    >
                        <span className='accessible'>Facebook</span>
                    </a>
                </li>

                <li className='post-share__item'>
                    <a
                        className='post-share__link post-share__link--twitter'
                        href={`http://twitter.com/share?text=Check%20out%20this%20blog%20post%20I%20liked:%20&url=${url}&hashtags=parkygames,gaming,youtuber`}
                        target='_blank'
                        rel="noopener noreferrer"
                    >
                        <span className='accessible'>Twitter</span>
                    </a>
                </li>

                <li className='post-share__item'>
                    <a
                        className='post-share__link post-share__link--email'
                        href={`mailto:?subject=${pageName}&body=Check%20out%20this%20blog%20post%20I%20liked:%20${url}`}
                    >
                        <span className='accessible'>Email</span>
                    </a>
                </li>

                <li className='post-share__item'>
                    <a
                        className='post-share__link post-share__link--copy'
                        href='/'
                        onClick={handleCopy}
                    >
                        <span className='accessible'>Email</span>
                    </a>
                </li>
            </ul>
            <p className={`post-share__notification${state.copied ? ' post-share__notification--active' : ''}`}>Copied!</p>
        </section>
    );
};

PostShare.propTypes = {
    variant: PropTypes.oneOf(['vertical']),
    sticky: PropTypes.bool,
};

export default PostShare;